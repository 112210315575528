import logo from './assets/lorem-logo.svg';
import heroNew from './assets/hero_new.svg';
import platformWix from './assets/platform_wix.svg';
import plusSign from './assets/plus_sign.svg';
import iconMagnifying from './assets/icon_magnifying.svg';
import iconPencil from './assets/icon_pencil.svg';
import iconStar from './assets/icon_star.svg';
import numberCircle from './assets/number_circle.svg';
import loremTrial from './assets/lorem_trial.png';
import loremDescription from './assets/lorem_description.png';
import loremSave from './assets/lorem_save.png';
import loremLine from './assets/lorem_line.svg';
import './App.css';

function App() {
  return (
    <div className="container">
      <div className="header">
        <img src={logo} />
        <div style={{float: 'right', position: 'relative'}}>
          <a href="https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wicm91dGVcIjpcInRlc3RBcHBcIixcImFwcERlZklkXCI6XCI2OTA2YzcwNS04ZmYyLTRhYjQtYWZhZC1hYTIwNjI4ZjQ2YzBcIixcInZlcnNpb25cIjpcImxhdGVzdFwiLFwiYXBwdXJpXCI6XCJodHRwczovL2Rlc2NyaXB0dmFpLnVjLnIuYXBwc3BvdC5jb20vc2lnbnVwXCJ9IiwiaWF0IjoxNjM4MDM5ODcxfQ.8xw97e-2y5w9DS0aXJiE1eCT3Aipt3-jQ6o5Dq2Gjnc" target="_blank">
            <button className="request-access-button">Install Now</button>
          </a>
        </div>
      </div>

      <div className="hero-container">
        <img src={heroNew} />
        <div>
          <img src={platformWix} className="hero_images" />
          <img src={plusSign} className="hero_images hero_images_middle" />
          <img src={logo} className="hero_images" />
        </div>

        <h1>Product Description Generator for Wix.</h1>
        <a href="https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wicm91dGVcIjpcInRlc3RBcHBcIixcImFwcERlZklkXCI6XCI2OTA2YzcwNS04ZmYyLTRhYjQtYWZhZC1hYTIwNjI4ZjQ2YzBcIixcInZlcnNpb25cIjpcImxhdGVzdFwiLFwiYXBwdXJpXCI6XCJodHRwczovL2Rlc2NyaXB0dmFpLnVjLnIuYXBwc3BvdC5jb20vc2lnbnVwXCJ9IiwiaWF0IjoxNjM4MDM5ODcxfQ.8xw97e-2y5w9DS0aXJiE1eCT3Aipt3-jQ6o5Dq2Gjnc" target="_blank">
          <button className="request-access-button">Install Now</button>
        </a>
      </div>

      <div>
        <h2>How does Lorem AI work with Wix?</h2>

        <div className="row">
          <div className="col-3">
            <div className="col-inner">
              <img src={iconMagnifying} />
              <p className="col-title">Find your product</p>
              <p className="col-description">Select from existing inventory or create a new description for an upcoming product.</p>
            </div>
            
          </div>
          <div className="col-3">
              <div className="col-inner">
                <img src={iconPencil} />
                <p className="col-title">Give a few details</p>
                <p className="col-description">Provide a brief one-to-two sentence summary describing what you’re looking for.</p>
              </div>
            
          </div>
          <div className="col-3">
              <div className="col-inner">
                <img src={iconStar} />
                <p className="col-title">Enjoy the magic</p>
                <p className="col-description">Choose from one of the 3-5 different generated descriptions and boom you’re done!</p>
              </div>
          
          </div>
        </div>
      </div>

      <div style={{marginTop: 130}}>
        <h2>How to get started.</h2>

        <div>
          <div style={{position: 'relative'}}>
            <div className="col-left-container">
              <div className="number">
                <span className="number-indicator">1</span>
                <img src={numberCircle} className="number-circle" />
              </div>
              <h3>Add the app to your Wix website.</h3>
              <div className="body">
                You can also search the Wix App Market for <strong>Lorem AI - Product Description Generator</strong><br /><br />
                Or, click the button below.
              </div>
              <a href="https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wicm91dGVcIjpcInRlc3RBcHBcIixcImFwcERlZklkXCI6XCI2OTA2YzcwNS04ZmYyLTRhYjQtYWZhZC1hYTIwNjI4ZjQ2YzBcIixcInZlcnNpb25cIjpcImxhdGVzdFwiLFwiYXBwdXJpXCI6XCJodHRwczovL2Rlc2NyaXB0dmFpLnVjLnIuYXBwc3BvdC5jb20vc2lnbnVwXCJ9IiwiaWF0IjoxNjM4MDM5ODcxfQ.8xw97e-2y5w9DS0aXJiE1eCT3Aipt3-jQ6o5Dq2Gjnc" target="_blank">
                <button className="request-access-button" style={{marginTop: 24, marginBottom: 96}}>Install Now</button>
              </a>
              {/* <button className="request-access-button" style={{marginTop: 24, marginBottom: 96}}>Install Now</button> */}
            </div>

            <div style={{position: 'absolute', right: 0, top: 0}}>
              <img src={loremTrial} style={{width: 480}} />
              <img src={loremLine} style={{position: 'absolute', bottom: -120, right: 140, background: 'transparent'}} />
            </div>
          </div>

          <div style={{position: 'relative'}}>
            <div className="col-left-container">
              <div className="number">
                <span className="number-indicator">2</span>
                <img src={numberCircle} className="number-circle" />
              </div>
              <h3>Select a product</h3>
              <div className="body" style={{marginBottom: 180}}>
                Choose from one of your existing inventory items or you can create an entirely new product description for something not yet in your store..
              </div>
            </div>

            <div style={{position: 'absolute', right: 0, top: 0}}>
              <img src={loremDescription} style={{width: 480}} />
              <img src={loremLine} style={{position: 'absolute', bottom: -120, right: 140, background: 'transparent'}} />
            </div>
          </div>

          <div style={{position: 'relative'}}>
            <div className="col-left-container">
              <div className="number">
                <span className="number-indicator">3</span>
                <img src={numberCircle} className="number-circle" />
              </div>
              <h3>Generate your description</h3>
              <div className="body">
                Select from one of the descriptions generated for you. Pick which one fits your brand the best, and if none of them do, simply run it again.
              </div>
            </div>

            <div style={{position: 'absolute', right: 0, top: 0}}>
              <img src={loremSave} style={{width: 480}} />
            </div>
          </div>
        </div>
      </div>

      <div style={{marginTop: 180}}>
        <h2>That’s all there is to it, what are you waiting for?</h2>
        <a href="https://www.wix.com/market?appMarketParams=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjoie1wicm91dGVcIjpcInRlc3RBcHBcIixcImFwcERlZklkXCI6XCI2OTA2YzcwNS04ZmYyLTRhYjQtYWZhZC1hYTIwNjI4ZjQ2YzBcIixcInZlcnNpb25cIjpcImxhdGVzdFwiLFwiYXBwdXJpXCI6XCJodHRwczovL2Rlc2NyaXB0dmFpLnVjLnIuYXBwc3BvdC5jb20vc2lnbnVwXCJ9IiwiaWF0IjoxNjM4MDM5ODcxfQ.8xw97e-2y5w9DS0aXJiE1eCT3Aipt3-jQ6o5Dq2Gjnc" target="_blank">
          <button className="request-access-button">Install Now</button>
        </a>
        <div style={{height: 120}}></div>
      </div>

      <div>
        <div className="footer-divider"></div>
        <div className="footer-contents">
          <div>
            © 2021 GLHF Ventures
          </div>

          <div className="footer_terms">
          <a href="https://wix.descriptiv.ca/PrivacyPolicy.pdf">Terms</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <a href="https://wix.descriptiv.ca/PrivacyPolicy.pdf">Privacy</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
